import { Button, Form, Input, Select, TreeSelect } from '@maxtropy/components';
import { Col, Row, TreeSelectProps } from 'antd';
import React, { useContext, useEffect, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { apiV2EnergyAnalysisListByOuPost } from '@maxtropy/device-customer-apis-v2';
import { EnergyUnitTreeItem, getEnergyUnitTree } from '@/api/energyUnitMapping';
import { isNil } from 'lodash-es';
import { ShareDataContext } from '../..';

const formatToNodeData = (data: EnergyUnitTreeItem[]): TreeSelectProps['treeData'] => {
  return data.map(i => {
    return {
      title: i.name,
      value: i.energyUnitId,
      disabled: i.disabled,
      children: i.children && i.children.length > 0 ? formatToNodeData(i.children) : [],
    };
  });
};

const MappingRelation = () => {
  const { form, isEdit } = useContext(ShareDataContext);
  const areaOne = Form.useWatch('areaOne', form);
  const areaTwo = Form.useWatch('areaTwo', form);
  const areaThree = Form.useWatch('areaThree', form);

  // 用能分析组
  const { data: unitGroup } = useRequest(() =>
    apiV2EnergyAnalysisListByOuPost({}).then(res => {
      return res.list;
    })
  );

  // 获取用能单元树1
  const { data: treeNodeDataOne } = useRequest(
    () =>
      getEnergyUnitTree(areaOne).then(res => {
        return formatToNodeData(res.list || []);
      }),
    {
      ready: !!areaOne,
      refreshDeps: [areaOne],
    }
  );
  // 获取用能单元树2
  const { data: treeNodeDataTwo } = useRequest(
    () =>
      getEnergyUnitTree(areaTwo).then(res => {
        return formatToNodeData(res.list || []);
      }),
    {
      ready: !!areaTwo,
      refreshDeps: [areaTwo],
    }
  );
  // 获取用能单元树3
  const { data: treeNodeDataThree } = useRequest(
    () =>
      getEnergyUnitTree(areaThree).then(res => {
        return formatToNodeData(res.list || []);
      }),
    {
      ready: !!areaThree,
      refreshDeps: [areaThree],
    }
  );

  const unitGroupOption = useMemo(() => {
    return (unitGroup ?? []).map(i => ({
      label: i.name,
      value: i.id,
    }));
  }, [unitGroup]);

  return (
    <div>
      {/* 区域1 */}
      <Row>
        <Col span={8}>
          <Form.Item label="区域1" name="areaOne" rules={[{ required: true, message: '请选择区域1' }]}>
            <Select
              style={{ width: '80%' }}
              showSearch
              placeholder="请选择区域1"
              optionFilterProp="label"
              options={unitGroupOption}
              disabled={!isEdit}
              onChange={() => form?.setFieldValue('energyOne', undefined)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="总用能" name="energyOne" rules={[{ required: true, message: '总用能' }]}>
            <TreeSelect
              disabled={!isEdit}
              treeData={treeNodeDataOne}
              maxTagCount={3}
              treeCheckStrictly
              treeCheckable
              showCheckedStrategy="SHOW_ALL"
              treeDefaultExpandAll
              placeholder="请选择"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="区域名称"
            name="areaNameOne"
            rules={[
              { required: true, message: '区域名称' },
              { min: 1, max: 20 },
            ]}
          >
            <Input placeholder="请输入" disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      {/* 区域2 */}
      <Row>
        <Col span={8}>
          <Form.Item label="区域2" name="areaTwo" rules={[{ required: true, message: '请选择区域2' }]}>
            <Select
              style={{ width: '80%' }}
              showSearch
              placeholder="请选择区域2"
              optionFilterProp="label"
              options={unitGroupOption}
              disabled={!isEdit}
              onChange={() => form?.setFieldValue('energyTwo', undefined)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="总用能" name="energyTwo" rules={[{ required: true, message: '总用能' }]}>
            <TreeSelect
              disabled={!isEdit}
              treeData={treeNodeDataTwo}
              maxTagCount={3}
              treeCheckStrictly
              treeCheckable
              showCheckedStrategy="SHOW_ALL"
              treeDefaultExpandAll
              placeholder="请选择"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="区域名称"
            name="areaNameTwo"
            rules={[
              { required: true, message: '区域名称' },
              { min: 1, max: 20 },
            ]}
          >
            <Input placeholder="请输入" disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      {/* 区域3 */}
      <Row>
        <Col span={8}>
          <Form.Item label="区域3" name="areaThree" rules={[{ required: true, message: '请选择区域3' }]}>
            <Select
              style={{ width: '80%' }}
              showSearch
              placeholder="请选择区域3"
              optionFilterProp="label"
              options={unitGroupOption}
              disabled={!isEdit}
              onChange={() => form?.setFieldValue('energyThree', undefined)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="总用能" name="energyThree" rules={[{ required: true, message: '请选择总用能' }]}>
            <TreeSelect
              disabled={!isEdit}
              treeData={treeNodeDataThree}
              maxTagCount={3}
              treeCheckStrictly
              treeCheckable
              showCheckedStrategy="SHOW_ALL"
              treeDefaultExpandAll
              placeholder="请选择"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="区域名称"
            name="areaNameThree"
            rules={[
              { required: true, message: '区域名称' },
              { min: 1, max: 20 },
            ]}
          >
            <Input placeholder="请输入" disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default MappingRelation;
