import { deviceTypesProps, getDeviceTypeTreeWithoutScene } from '@/api/circuit';
import {
  CreateUETEMTResponse,
  DeviceResProps,
  DeviceVoProps,
  getUetEMTcandidateDeviceList,
  getUetEMTcandidateLevel1TypeIdsList,
} from '@/api/uet';
import { EllipsisSpan, Filter, Form, Input, Modal, Paging, Select, Table, useSearchParams } from '@maxtropy/components';
import React, { Key, Ref, useEffect, useImperativeHandle, useState } from 'react';
import styles from './index.module.scss';
import { TOTALIZERID } from '../DeviceListForm';
import { Space } from 'antd';
import { isNil } from 'lodash-es';

interface DeviceModalProps {
  disabledItems?: DeviceVoProps[];
  visible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  selectedOuIds?: number[];
  eMTBasicInfo?: CreateUETEMTResponse;
  energyMediumId?: number;
}

export interface DeviceModalRef {
  selectedRows: Array<DeviceVoProps>;
}

const columns = [
  {
    title: '设备名称',
    dataIndex: ['deviceVo', 'name'],
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: ['deviceVo', 'code'],
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: ['deviceVo', 'typeName'],
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: ['deviceVo', 'tags'],
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v && v.length > 0 ? v.join('、') : '--'} />,
  },
  {
    title: '所属组织',
    dataIndex: ['deviceVo', 'customerName'],
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface SearchParams {
  codeOrName?: string;
  tag?: string;
  typeIds?: number[];
}

const DEVICE_ID = 5000; // 电表一级类目id

const DeviceModal = React.forwardRef((props: DeviceModalProps, ref: Ref<DeviceModalRef>) => {
  const { disabledItems, visible, onOk, onCancel, selectedOuIds, eMTBasicInfo, energyMediumId } = props;
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { pageOffset, pageSize, setSearchParams, searchParams, setPageOffset, setTotalCount, finish, reset } =
    pagingInfo;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<DeviceVoProps[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [data, setData] = useState<DeviceResProps[]>([]);

  const [deviceTypeTree, setDeviceTypeTree] = useState<deviceTypesProps[]>([]);
  const [deviceType2LevelList, setDeviceType2LevelList] = useState<deviceTypesProps[]>([]);
  const [deviceType1LevelList, setDeviceType1LevelList] = useState<deviceTypesProps[]>([]);

  const [value1Level, setValue1Level] = useState<number>();

  useEffect(() => {
    if (disabledItems) {
      setSelectedRowKeys((disabledItems ?? []).map(i => i.id));
      setSelectedRows(disabledItems);
    }
  }, [disabledItems]);
  // 查询类目树
  useEffect(() => {
    if (visible) {
      getDeviceTypeTreeWithoutScene().then(res => {
        setDeviceTypeTree(res.deviceTypes);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // 查询一级类目
  useEffect(() => {
    if (!energyMediumId) {
      setDeviceType1LevelList([]);
    } else {
      if (energyMediumId === 2030 && deviceTypeTree && deviceTypeTree.length) {
        // 如果能源介质为电能
        const dataType1LevelList = formatTreeData1LevelById(deviceTypeTree, [DEVICE_ID]);
        setDeviceType1LevelList(dataType1LevelList);
      } else {
        if (eMTBasicInfo && deviceTypeTree && deviceTypeTree.length) {
          getUetEMTcandidateLevel1TypeIdsList(String(eMTBasicInfo.uetId), {
            energyMediumId: String(energyMediumId),
          }).then(res => {
            const dataType1LevelList = formatTreeData1LevelById(deviceTypeTree, res);
            setDeviceType1LevelList(dataType1LevelList);
          });
        }
      }
    }
  }, [eMTBasicInfo, energyMediumId, deviceTypeTree]);

  // 通过一级类目筛选二级类目
  useEffect(() => {
    if (value1Level && deviceType1LevelList && deviceType1LevelList.length) {
      let dataTypeList = formatTreeDataById(deviceType1LevelList, value1Level);
      setDeviceType2LevelList(dataTypeList);
    } else {
      setDeviceType2LevelList([]);
    }
  }, [value1Level, deviceType1LevelList]);

  useImperativeHandle(ref, () => ({ selectedRows: selectedRows }));

  // 查询数据
  useEffect(() => {
    if (visible) {
      const hasOuIds = !!(selectedOuIds && selectedOuIds.length); // 判断ouIds是否存在
      const hasUetId = !!(eMTBasicInfo && eMTBasicInfo.uetId); // 判断生成的uetId是否存在
      const hasDeviceType1LevelList = !!(deviceType1LevelList && deviceType1LevelList.length); // 判断通过能源介质查找的一级类目是否存在
      if (!hasOuIds) {
        Modal.error({
          content: `请先选择所属运营单元！`,
        });
        setIsLoading(true);
      } else if (!energyMediumId) {
        Modal.error({
          content: `请先选择能源介质！`,
        });
        setIsLoading(true);
      } else {
        if (JSON.stringify(searchParams) !== '{}' && searchParams && hasUetId && hasOuIds && hasDeviceType1LevelList) {
          setIsLoading(true);
          getUetEMTcandidateDeviceList(String(eMTBasicInfo!.uetId), {
            ...searchParams,
            typeIds:
              searchParams.typeIds ??
              (value1Level
                ? formatTreeDataById(deviceType1LevelList, value1Level).map(i => i.id)
                : getAllTreeDataByLevel1Id(deviceType1LevelList)),
            ouIds: selectedOuIds,
            page: pageOffset,
            size: pageSize,
          }).then(res => {
            if (res) setTotalCount(res.total);
            setData(res.list);
            setIsLoading(false);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, eMTBasicInfo, deviceType1LevelList]);

  const onReset = () => {
    setValue1Level(undefined);
    reset();
  };

  const filters = (
    <Filter onFinish={v => finish(v as SearchParams)} form={form} onReset={onReset}>
      <Space size={20} wrap>
        <Form.Item name="codeOrName" label="编号/名称">
          <Input placeholder="请输入编号或名称查询" />
        </Form.Item>
        <Form.Item label="一级类目">
          <Select
            allowClear
            onClear={() => {
              setValue1Level(undefined);
              form.setFieldValue('typeIds', undefined);
            }}
            onChange={id => setValue1Level(id)}
            value={value1Level}
            style={{ width: 200 }}
            placeholder="请选择"
          >
            {deviceType1LevelList?.map(i => (
              <Select.Option key={i.id} value={i.id}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="二级类目" name="typeIds">
          <Select style={{ width: 200 }} placeholder="请选择">
            {deviceType2LevelList?.map(i => (
              <Select.Option key={i.id} value={i.id}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="设备标签" name="tag">
          <Input placeholder="请输入" />
        </Form.Item>
      </Space>
    </Filter>
  );

  const onSelectChange = (rowKeys: Key[], rows: DeviceResProps[]) => {
    // 因为Rows有缓存问题，所以过滤加去重
    console.log('selectedRows', selectedRows);
    console.log('rows', rows);
    const formatRows = rows
      .filter(c => !isNil(c))
      .map(i => ({
        id: i.deviceVo.id,
        rootTypeId: i.rootTypeId,
        name: i.deviceVo.name,
        typeName: i.deviceVo.typeName,
      }));
    let tmpSelectedRows = uniqueFunc([...selectedRows, ...formatRows].filter(item => !isNil(item)));
    tmpSelectedRows = tmpSelectedRows.filter(item => rowKeys.indexOf(item.id) >= 0);

    if (tmpSelectedRows.length > 1 && tmpSelectedRows.map(i => i.rootTypeId).includes(TOTALIZERID)) {
      Modal.error({
        content: `已有积算仪设备或已选择其他一级类目的设备，请先移除后再进行添加！`,
      });
    } else {
      let res = judgeRepetSelectedDevice(tmpSelectedRows.map(i => i.rootTypeId!));
      // 如果res不是空的话，表示有重复项
      if (JSON.stringify(res) !== '{}') {
        Modal.error({
          content: (
            <>
              每种类型（一级类目）下仅支持选择1个设备，如需更换设备，请先移除
              <span style={{ color: 'red' }}>
                【{tmpSelectedRows[tmpSelectedRows.length - 1].typeName?.split('/')[1]}】
              </span>
              一级类目下的设备
            </>
          ),
        });
      } else {
        setSelectedRowKeys(rowKeys);
        setSelectedRows(tmpSelectedRows);
      }
    }
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    selectedRowKeys: selectedRowKeys,
  };

  return (
    <>
      <Modal
        open={visible}
        title="设备选择"
        width={1200}
        destroyOnClose
        onOk={() => {
          setPageOffset(1);
          setValue1Level(undefined);
          form.setFieldValue('typeIds', undefined);
          setSearchParams?.({ typeIds: undefined, pageOffset: 1, pageSize: 50 });
          onOk?.();
        }}
        onCancel={() => {
          setPageOffset(1);
          onCancel?.();
          setValue1Level(undefined);
          form.setFieldValue('typeIds', undefined);
          setSearchParams?.({ typeIds: undefined, pageOffset: 1, pageSize: 50 });
          setSelectedRowKeys((disabledItems ?? []).map(i => i.id));
        }}
      >
        {filters}
        <div className={styles.table_text}>
          <span>
            已选择<span className={styles.red}>{selectedRowKeys.length}</span>项
          </span>
          <span>
            点击前往
            <a
              rel="noreferrer"
              href={`${(window as any).IOTPLATFORMORIGIN}/device/manage/device/create`}
              target="_blank"
            >
              添加设备
            </a>
          </span>
        </div>
        <Table
          loading={isLoading}
          sticky
          rowSelection={{
            type: 'checkbox',
            hideSelectAll: true,
            ...rowSelection,
          }}
          dataSource={data}
          rowKey={record => record.deviceVo.id}
          scroll={{ y: 300 }}
          columns={columns}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </Modal>
    </>
  );
});

export default DeviceModal;

// 二级通过名字
// export const formatTreeData = (tree: deviceTypesProps[], typeName: string) => {
//   let res: deviceTypesProps[] = [];
//   tree.forEach(i => {
//     if (i.name === typeName) {
//       res = res.concat(i.children ?? []);
//     }
//   });
//   return res;
// };

// 通过一级id查询二级JSONArray
export const formatTreeDataById = (tree: deviceTypesProps[], id: number) => {
  let res: deviceTypesProps[] = [];
  tree.forEach(i => {
    if (i.id === id) {
      res = res.concat(i.children ?? []);
    }
  });
  return res;
};

// 通过id查一级类目树，返回JSONArray
export const formatTreeData1LevelById = (tree: deviceTypesProps[], ids: number[]) => {
  let res: deviceTypesProps[] = [];
  ids.forEach(id => {
    let find = tree.find(item => item.id === id);
    if (find) {
      res.push(find);
    }
  });
  return res;
};

// 一级list拿到所有二级类目id
export const getAllTreeDataByLevel1Id = (tree: deviceTypesProps[]) => {
  let res: any[] = [];
  tree.forEach(item => {
    let m = item.children?.map(i => i.id);
    res.push(m);
  });
  return res.flat();
};

// 寻找重复元素并计数，用于选择设备的时候进行校验
export const judgeRepetSelectedDevice = (arr: number[]) => {
  let newArr: any[] = [];
  for (let j = 0; j < arr.length; j++) {
    if (arr.indexOf(arr[j]) !== arr.lastIndexOf(arr[j])) {
      newArr.push(arr[j]);
    }
  }
  let countArrs = [];
  let numArr = newArr.reduce(function (prev, next) {
    prev[next] = prev[next] + 1 || 1;
    countArrs.push({ prev });
    return prev;
  }, {});
  return numArr;
};

export const uniqueFunc = (arr: DeviceVoProps[]) => {
  const res = new Map();
  return arr.filter(item => !res.has(item[`id`]) && res.set(item[`id`], 1));
};
