import React, { FC } from 'react';
import styles from './index.module.scss';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

interface IHeaderProps {
  fullBtn: () => void;
  isFullScreen: boolean;
}

const Header: FC<IHeaderProps> = props => {
  const { fullBtn, isFullScreen } = props;
  const navigate = useNavigate();

  return (
    <div className={styles.header_area_box}>
      <div className={styles.date_box}></div>
      <div className={styles.title_box}>精益能碳综合驾驶舱</div>
      <div className={styles.set_box}>
        {/* 全屏按钮 */}
        <div className={styles.refreshTimeSty}>{dayjs().format('YYYY-MM-DD HH:mm:ss')}</div>
        <div
          className={styles.btn}
          onClick={() => {
            navigate('/factory/zyf/dashboard/config');
          }}
        >
          &nbsp;
          <span className={styles.text}>{'配置'}</span>
          <div className={styles.bottomLine} />
        </div>
        <div className={styles.btn} onClick={fullBtn}>
          {isFullScreen ? (
            <FullscreenExitOutlined className={styles.icon} />
          ) : (
            <FullscreenOutlined className={styles.icon} />
          )}
          &nbsp;
          <span className={styles.text}>{isFullScreen ? '退出' : '全屏'}</span>
          <div className={styles.bottomLine} />
        </div>
      </div>
    </div>
  );
};

export default Header;
