import React, { CSSProperties } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import bg from '../../imgs/small_title_bg.png';

export interface CardBoxProps {
  title?: React.ReactNode;
  children: React.ReactNode;
  extraContent?: React.ReactNode;
  style?: CSSProperties;
  showIcon?: boolean;
  className?: string;
}

const CardBox: React.FC<CardBoxProps> = props => {
  const { title, style, extraContent, className } = props;

  return (
    <div className={styles.personalFormContainer} style={style}>
      <div className={styles.headerWrapper}>
        {title ? <div className={styles.formTitle}>{title}</div> : <div />}
        {extraContent ? extraContent : <div />}
      </div>

      <div className={classNames(styles.content, className)}>{props.children}</div>
    </div>
  );
};

export default CardBox;
