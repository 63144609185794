import { OrganizationResponse } from '@/api/device';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';

export const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};
