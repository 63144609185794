import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Col, Form, Row } from 'antd';
import { Input, Modal, Radio, Select, message, InputNumber } from '@maxtropy/components';
import { isEmpty, isNil } from 'lodash-es';
import {
  ActionType,
  BIT_MODE,
  BIT_NUM,
  BYTE_NUM,
  DataPointType,
  DataProperty,
  DataPropertyType,
  LENGTH,
  OBJECT_TYPE,
  VALUE_TYPE,
  ModbusPointBase,
} from '../../../../../types';
import { PointContext } from '../../../contextTypes';
import { useDataProperties } from '../hooks';
import {
  bitModeFormatter,
  byteNumFormatter,
  bitNumFormatter,
  handleModeFormatter1,
  handleModeFormatter2,
  handleModeFormatter3,
  lengthFormatter,
  objectFormatter,
  valueTypeFormatter1,
  valueTypeFormatter2,
  valueTypeFormatter3,
  valueTypeFormatter4,
  checkNumCoefficient,
  modeOptions,
  checkNum,
  identifierValidatorBuilder,
} from '../entity';
import FormSubmit from '../../FormSubmit';
import EnumMapping, { EditableTableRef } from '../components/EnumMapping';
import { FilterMode, FilterModeDisplay } from '@/shared/const';
import { validatorValueRange } from '@/shared/utils/utils';

const { Option } = Select;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export function getValueTypeMapping(objectType: OBJECT_TYPE, length: LENGTH, mode: string) {
  let newValueType;
  if (objectType === OBJECT_TYPE.COILSTATUS || objectType === OBJECT_TYPE.INPUTSTATUS) {
    newValueType = valueTypeFormatter1;
  } else if (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) {
    if (length === LENGTH.ONE) {
      if (
        mode === bitModeFormatter[BIT_MODE.NULL] ||
        mode === byteNumFormatter[BYTE_NUM.HIGHBYTE] ||
        mode === byteNumFormatter[BYTE_NUM.LOWBYTE]
      ) {
        // 无或者按字节
        newValueType = valueTypeFormatter2;
      } else if ((mode || '').includes('操作位')) {
        // 按位
        newValueType = valueTypeFormatter1;
      }
    } else if (length === LENGTH.TWO) {
      newValueType = valueTypeFormatter3;
    } else if (length === LENGTH.FOUR) {
      newValueType = valueTypeFormatter4;
    }
  }
  return newValueType;
}

export function getHandleModeMapping(objectType: OBJECT_TYPE, length: LENGTH) {
  let newHandleMode;
  if (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) {
    if (length === LENGTH.ONE) {
      newHandleMode = handleModeFormatter1;
    } else if (length === LENGTH.TWO) {
      newHandleMode = handleModeFormatter2;
    } else if (length === LENGTH.FOUR) {
      newHandleMode = handleModeFormatter3;
    }
  }
  return newHandleMode;
}

export function getDefaultHandleModeAndValueType(length: LENGTH | undefined, mode: string | undefined) {
  if (length === LENGTH.ONE) {
    if (mode === bitModeFormatter[BIT_MODE.NULL]) {
      return {
        handleMode: 1, // 2,1
        valueType: VALUE_TYPE.UNSIGNEDINTERGER, // 无符号整型数
      };
    } else if (mode === byteNumFormatter[BYTE_NUM.HIGHBYTE] || mode === byteNumFormatter[BYTE_NUM.LOWBYTE]) {
      return {
        valueType: VALUE_TYPE.UNSIGNEDINTERGER,
      };
    } else if ((mode || '').includes('操作位')) {
      return {
        valueType: VALUE_TYPE.BOOLEAN,
      };
    }
  }
  if (length === LENGTH.TWO) {
    // 此时mode只会是无
    return {
      handleMode: 3, // 4,3,2,1
      valueType: VALUE_TYPE.FLOAT, // 单精度浮点数
    };
  }
  if (length === LENGTH.FOUR) {
    // 此时mode只会是无
    return {
      handleMode: 7, // 8,7,6,5,4,3,2,1
      valueType: VALUE_TYPE.DOBULE, // 双精度浮点数
    };
  }
  return {};
}

export function kOrbDisabled(objectType: OBJECT_TYPE) {
  const disabled = !(objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER);
  return disabled;
}

export function getBitModeAndByteNumAndBitNum(mode: string | undefined) {
  if (mode === bitModeFormatter[BIT_MODE.NULL]) {
    return {
      bitMode: BIT_MODE.NULL,
      byteNum: null,
      bitNum: null,
    };
  } else if (mode === byteNumFormatter[BYTE_NUM.HIGHBYTE] || mode === byteNumFormatter[BYTE_NUM.LOWBYTE]) {
    const target = Object.entries(byteNumFormatter).find(([key, value]) => value === mode);
    return {
      bitMode: BIT_MODE.BYTE,
      byteNum: target ? Number(target[0]) : null,
      bitNum: null,
    };
  } else if ((mode || '').includes('操作位')) {
    const target = Object.entries(bitNumFormatter).find(([key, value]) => value === mode);
    return {
      bitMode: BIT_MODE.BIT,
      byteNum: null,
      bitNum: target ? Number(target[0]) : null,
    };
  }
  return {};
}

export function getMode(bitMode: BIT_MODE | undefined, bitNum: BIT_NUM | undefined, byteNum: BYTE_NUM | undefined) {
  let value;
  if (bitMode === BIT_MODE.NULL) {
    value = bitModeFormatter[BIT_MODE.NULL];
  } else if (bitMode === BIT_MODE.BIT && !isNil(bitNum)) {
    value = bitNumFormatter[bitNum];
  } else if (bitMode === BIT_MODE.BYTE && !isNil(byteNum)) {
    value = byteNumFormatter[byteNum];
  }
  return value;
}

export const computeNextStarting = (currentStarting: string, length?: number): string => {
  const next = parseInt(currentStarting, 16) + (length ?? 0);
  return next.toString(16);
};

export interface FormModbusPoint extends ModbusPointBase {
  actionType?: ActionType;
  id?: number;
  pointType?: DataPointType;
  hasProperty?: boolean;
  writable?: boolean;
  dataPropertyId?: number;
  physicalUnitId?: number;
  dataPropertyName?: string;
  physicalUnitGeneralName?: string;
  identifier?: string;
}

export interface PointFormDialogProps {
  row?: FormModbusPoint;
  dataPropertiesAll?: DataProperty[];
  usedProperties?: number[];
  usedIdentifier?: string[];
  onCancel: () => void;
  onOk: (values: any) => void;
}

const PointFormDialog: React.FC<PointFormDialogProps> = ({
  row,
  dataPropertiesAll,
  usedProperties,
  usedIdentifier,
  onCancel,
  onOk,
}) => {
  const { setRow } = useContext(PointContext);
  const { dataProperties, dataPropertyId, setDataPropertyId, units, dataPropertyType } = useDataProperties(
    dataPropertiesAll,
    usedProperties
  );

  const [form] = Form.useForm();
  const { setFieldsValue, getFieldValue } = form;
  const tableRef = useRef<EditableTableRef | null>(null);
  const valueFilterMode = Form.useWatch('valueFilterMode', form);

  const initStatusRef = useRef(true);

  const initFormValues = useCallback(
    (
      objectType: OBJECT_TYPE | undefined,
      length: LENGTH | undefined,
      mode: string | undefined,
      handleMode: number | undefined,
      valueType: VALUE_TYPE | undefined,
      starting: number | undefined,
      enumMapping: object | undefined,
      isEnumMapping: boolean | undefined,
      rest: object = {}
    ) => {
      Promise.resolve()
        .then(() => setFieldsValue({ objectType }))
        .then(() => setFieldsValue(Object.assign({ length }, !isNil(mode) ? { mode } : {})))
        .then(() =>
          setFieldsValue(
            Object.assign(
              {
                ...rest,
              },
              !isNil(valueType) ? { valueType } : {},
              !isNil(handleMode) ? { handleMode } : {},
              !isNil(starting) ? { starting: `0x${starting.toString(16)}` } : {},
              { IsEnumMapping: isEnumMapping },
              !isNil(enumMapping) ? { enumMapping } : {}
            )
          )
        )
        .finally(() => {
          initStatusRef.current = false;
        });
    },
    [setFieldsValue]
  );

  // 初始化
  useEffect(() => {
    if (row && !isNil(row.starting)) {
      // 编辑 和 保存并继续添加
      const {
        objectType,
        length,
        handleMode,
        valueType,
        bitMode,
        bitNum,
        byteNum,
        starting,
        enumMapping,
        isEnumMapping = !isNil(enumMapping),
        ...rest
      } = row;
      const mode = getMode(bitMode, bitNum, byteNum);
      initFormValues(objectType, length, mode, handleMode, valueType, starting, enumMapping, isEnumMapping, rest);
    } else {
      // 新建
      const { handleMode, valueType } = getDefaultHandleModeAndValueType(LENGTH.ONE, bitModeFormatter[BIT_MODE.NULL]);
      initFormValues(
        OBJECT_TYPE.SAVEREGISTER,
        LENGTH.ONE,
        bitModeFormatter[BIT_MODE.NULL],
        handleMode,
        valueType,
        undefined,
        undefined,
        true,
        row
      );
    }
  }, [initFormValues, row]);

  // 改变objectType
  const onObjectTypeChange = (objectType: OBJECT_TYPE) => {
    if (initStatusRef.current) return;
    if (objectType === OBJECT_TYPE.COILSTATUS || objectType === OBJECT_TYPE.INPUTSTATUS) {
      setFieldsValue({
        valueType: VALUE_TYPE.BOOLEAN,
      });
    }
    if (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) {
      Promise.resolve()
        .then(() =>
          setFieldsValue({
            length: LENGTH.ONE,
            mode: bitModeFormatter[BIT_MODE.NULL],
          })
        )
        .then(() => setFieldsValue(getDefaultHandleModeAndValueType(LENGTH.ONE, bitModeFormatter[BIT_MODE.NULL])));
    }
  };

  // 改变length
  const onLengthChange = (length: LENGTH) => {
    if (initStatusRef.current) return;
    Promise.resolve()
      .then(() =>
        setFieldsValue({
          mode: bitModeFormatter[BIT_MODE.NULL],
        })
      )
      .then(() => {
        setFieldsValue(getDefaultHandleModeAndValueType(length, bitModeFormatter[BIT_MODE.NULL]));
      });
  };

  // 改变mode
  const onModeChange = (mode: string) => {
    if (initStatusRef.current) return;
    setFieldsValue(getDefaultHandleModeAndValueType(getFieldValue('length'), mode));
  };

  const onValuesChange = (changedValues: { [key: string]: any }) => {
    const { objectType, length, mode, starting } = changedValues;

    if (starting && /([A-Fa-f])$/.test(`${starting}`) && !starting.startsWith('0x')) {
      form.setFieldsValue({ starting: '0x' + starting });
    }

    if (!isNil(objectType)) {
      onObjectTypeChange(objectType);
    }
    if (!isNil(length)) {
      onLengthChange(length);
    }
    if (!isNil(mode)) {
      onModeChange(mode);
    }
  };

  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  useEffect(() => {
    if (dataPropertyType === DataPropertyType.YC && !isEmpty(units) && row) {
      form.setFieldsValue({
        physicalUnitId: units?.find(i => i.id === row.physicalUnitId)?.id ?? units?.find(unit => unit.default)?.id,
        physicalUnitGeneralName:
          units?.find(i => i.id === row.physicalUnitId)?.generalName ?? units?.find(unit => unit.default)?.generalName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyId, dataPropertyType, units, row]);

  const submit = async (shouldContinue?: boolean) => {
    await tableRef.current?.validate?.();
    if (tableRef.current?.isValiDataSameValue !== undefined || tableRef.current?.isValidRequire !== undefined) {
      if (!tableRef.current?.isValidRequire || !tableRef.current?.isValiDataSameValue) {
        return;
      }
    }
    const values = await form.validateFields();
    const { mode, ...rest } = values;
    const submitValues = {
      ...rest,
      k: values.k ?? 1,
      b: values.b ?? 0,
      enumMapping: tableRef.current?.tempObj,
      ...getBitModeAndByteNumAndBitNum(mode),
    };
    if (shouldContinue) {
      await onOk(submitValues);
      form.resetFields();
      const starting = computeNextStarting(values.starting as string, values.length);
      setRow?.({
        ...values,
        pointType: DataPointType.BASE_POINT,
        dataPropertyId: undefined,
        physicalUnitId: undefined,
        identifier: undefined,
        starting,
      });
    } else {
      await onOk(submitValues);
      onCancel();
    }
  };

  const IsEnumMapping = Form.useWatch('IsEnumMapping', form);

  return (
    <Modal
      width={600}
      open={row?.pointType === DataPointType.BASE_POINT}
      onCancel={onCancel}
      title={`${row?.id ? '编辑' : '添加'}采集点`}
      centered={true}
      maskClosable={false}
      footer={<FormSubmit submit={submit} cancel={onCancel} />}
    >
      <div style={{ height: 600, overflow: 'auto' }}>
        <Form {...formLayout} form={form} labelWrap={true} onValuesChange={onValuesChange}>
          <Form.Item noStyle name="dataPropertyName" />
          <Form.Item noStyle name="physicalUnitGeneralName" />
          <Form.Item
            name="hasProperty"
            label="采集点类型"
            initialValue={true}
            rules={[{ required: true, message: '请选择' }]}
            shouldUpdate
          >
            <Radio.Group disabled={!isNil(row?.id)}>
              <Radio value={true}>建模采集点</Radio>
              <Radio value={false}>非建模采集点</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            dependencies={['hasProperty']}
            shouldUpdate={(prevValues, curValues) => prevValues.hasProperty !== curValues.hasProperty}
          >
            {({ getFieldValue }) =>
              getFieldValue('hasProperty') ? (
                <>
                  <Form.Item
                    name="dataPropertyId"
                    label="数据属性"
                    rules={[{ required: true, message: '请选择数据属性' }]}
                  >
                    <Select
                      onChange={value => {
                        form.setFieldsValue({
                          dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                        });
                        setDataPropertyId(value as number);
                        form.setFieldsValue({
                          physicalUnitId: undefined,
                          physicalUnitGeneralName: '',
                        });
                      }}
                      placeholder="请输入/选择"
                      showSearch
                      filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      {dataProperties?.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {dataPropertyType === DataPropertyType.YC && (
                    <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                      <Select
                        onChange={value => {
                          form.setFieldsValue({
                            physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                          });
                        }}
                        placeholder="请选择"
                      >
                        {units?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.generalName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              ) : (
                <Form.Item
                  required
                  name="identifier"
                  label="非建模采集点标识符"
                  rules={[
                    {
                      validator: identifierValidatorBuilder(usedIdentifier),
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item
            name="writable"
            label="是否可写"
            initialValue={false}
            rules={[{ required: true, message: '请选择' }]}
          >
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="objectType" label="功能码" rules={[{ required: true, message: '请选择' }]}>
            <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}>
              <Option value={OBJECT_TYPE.COILSTATUS}>{objectFormatter[OBJECT_TYPE.COILSTATUS]}</Option>
              <Option value={OBJECT_TYPE.INPUTSTATUS}>{objectFormatter[OBJECT_TYPE.INPUTSTATUS]}</Option>
              <Option value={OBJECT_TYPE.SAVEREGISTER}>{objectFormatter[OBJECT_TYPE.SAVEREGISTER]}</Option>
              <Option value={OBJECT_TYPE.INPUTREGISTER}>{objectFormatter[OBJECT_TYPE.INPUTREGISTER]}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            required
            name="starting"
            label="开始地址"
            initialValue={'0x0'}
            rules={[
              {
                validator: async (rule, value) => {
                  if (isNil(value)) {
                    return Promise.reject('请选择');
                  }
                  if (!/^(0x)?([0-9A-Fa-f]{1,10})$/.test(`${value}`)) {
                    return Promise.reject(new Error('请输入不超过10位数字'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="请输入不超过10位数字" />
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              return (
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) && (
                  <Form.Item name="length" label="长度" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder="请选择"
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      <Option value={LENGTH.ONE}>{lengthFormatter[LENGTH.ONE]}</Option>
                      <Option value={LENGTH.TWO}>{lengthFormatter[LENGTH.TWO]}</Option>
                      <Option value={LENGTH.FOUR}>{lengthFormatter[LENGTH.FOUR]}</Option>
                    </Select>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType', 'length']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              const length = getFieldValue('length');
              return (
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) && (
                  <Form.Item name="mode" label="位操作模式" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder="请选择"
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      {(length === LENGTH.ONE ? modeOptions : modeOptions.slice(0, 1)).map(key => (
                        <Option key={key} value={key}>
                          {key}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType', 'length', 'mode']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              const length = getFieldValue('length');
              const mode = getFieldValue('mode');
              const formatter = getHandleModeMapping(objectType, length);
              const visible =
                (objectType === OBJECT_TYPE.SAVEREGISTER || objectType === OBJECT_TYPE.INPUTREGISTER) &&
                length !== undefined &&
                (length !== LENGTH.ONE || mode === bitModeFormatter[BIT_MODE.NULL]);

              return (
                visible && (
                  <Form.Item name="handleMode" label="处理方式" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder="请选择"
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      {Object.entries(formatter || {}).map(([value, key]) => {
                        return (
                          <Option key={value} value={Number(value)}>
                            {key}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['objectType', 'length', 'mode']} shouldUpdate>
            {({ getFieldValue }) => {
              const objectType = getFieldValue('objectType');
              const length = getFieldValue('length');
              const mode = getFieldValue('mode');
              const formatter = getValueTypeMapping(objectType, length, mode);
              return (
                <Form.Item name="valueType" label="解码类型" rules={[{ required: true, message: '请选择' }]}>
                  <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}>
                    {Object.entries(formatter || {}).map(([value, key]) => {
                      return (
                        <Option key={value} value={Number(value)}>
                          {key}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
          {/* 枚举值映射 */}
          {dataPropertyType === DataPropertyType.ENUM && (
            <>
              <Form.Item
                name="IsEnumMapping"
                label="枚举映射"
                rules={[{ required: true, message: '请选择是否枚举映射' }]}
                // initialValue={row?.enumMapping !== undefined ? (row?.enumMapping === null ? false : true) : true}
              >
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
              {/* 枚举映射框 */}
              <Form.Item noStyle dependencies={['IsEnumMapping', 'dataPropertyId']}>
                {({ getFieldValue }) => {
                  return (
                    getFieldValue('IsEnumMapping') === true && (
                      <Form.Item name="enumMapping" noStyle>
                        <EnumMapping ref={tableRef} dataProperties={dataProperties} />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            </>
          )}

          {
            <Form.Item noStyle dependencies={['objectType', 'IsEnumMapping']} shouldUpdate>
              {({ getFieldValue }) => {
                const objectType = getFieldValue('objectType');
                const disabled = kOrbDisabled(objectType);
                return (
                  !IsEnumMapping && (
                    <>
                      <Form.Item
                        name="k"
                        label="乘积系数k"
                        tooltip="y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数"
                        initialValue={1}
                        rules={[{ required: true, message: '请输入' }, { validator: checkNumCoefficient }]}
                      >
                        <Input placeholder="请输入-999999999~999999999的数字" disabled={disabled} />
                      </Form.Item>
                      <Form.Item
                        name="b"
                        label="偏移系数b"
                        tooltip="y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数"
                        initialValue={0}
                        rules={[{ required: true, message: '请输入' }, { validator: checkNumCoefficient }]}
                      >
                        <Input placeholder="请输入-999999999~999999999的数字" disabled={disabled} />
                      </Form.Item>
                    </>
                  )
                );
              }}
            </Form.Item>
          }
          <Form.Item
            name="valueFilterMode"
            label="数值过滤模式"
            initialValue={0}
            rules={[{ required: true, message: '请选择' }]}
            shouldUpdate
          >
            <Radio.Group
              onChange={v => {
                if (v.target?.value === FilterMode.GROWTHREASONABLERANGE) {
                  message.warning('新网关版本在3.7.30版本以上');
                }
              }}
            >
              {Object.entries(FilterModeDisplay).map(([k, v]) => (
                <Radio key={k} value={Number(k)}>
                  {v}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          {valueFilterMode === 1 && (
            <Form.Item
              label="数值合理区间"
              name="valueRange"
              dependencies={['valueFloor', 'valueCeiling']}
              rules={[
                form => ({
                  validator: () => validatorValueRange(form),
                }),
              ]}
              tooltip="区间外数值将被直接滤除，视为无效数值"
            >
              <Row gutter={8}>
                <Col span={11}>
                  <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={2}>--</Col>
                <Col span={11}>
                  <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          )}
          {valueFilterMode === 2 && (
            <>
              <Form.Item
                label="增长率合理区间"
                required={true}
                name="valueRange"
                dependencies={['valueFloor', 'valueCeiling']}
                rules={[
                  form => ({
                    validator: () => validatorValueRange(form),
                  }),
                ]}
                tooltip="新网关版本在3.7.30版本以上"
              >
                <Row gutter={8}>
                  <Col span={9}>
                    <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>--</Col>
                  <Col span={9}>
                    <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>/min</Col>
                </Row>
              </Form.Item>
              <Form.Item
                name="valueFilterCnt"
                label="边缘侧异常数据过滤个数"
                initialValue={5}
                rules={[{ required: true, message: '请输入' }]}
              >
                <InputNumber
                  placeholder="请输入"
                  min={1}
                  max={20}
                  precision={0}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
              <Form.Item
                label="云端增长率合理区间"
                name="cloudValueRange"
                dependencies={['cloudValueFloor', 'cloudValueCeiling']}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item name="cloudValueFloor" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>--</Col>
                  <Col span={8}>
                    <Form.Item name="cloudValueCeiling" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>{units?.find(i => i.default)?.generalName ?? '-'}/min</Col>
                </Row>
              </Form.Item>
              <Form.Item name="cloudValueFilterCnt" label="云端异常数据过滤个数" initialValue={5}>
                <InputNumber
                  placeholder="请输入"
                  min={1}
                  max={1000}
                  precision={0}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
            </>
          )}
          <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '长度不能超过50个字符' }]}>
            <Input placeholder="请输入备注" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default PointFormDialog;
