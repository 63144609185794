export enum TabType {
  WORKSHIP1 = 0, // 车间1
  WORKSHIP2, // 车间2
  WORKSHIP3, // 车间3
  WORKSHIP4, // 车间4
}

export const tabTypeDisplay = {
  [TabType.WORKSHIP1]: '车间1',
  [TabType.WORKSHIP2]: '车间2',
  [TabType.WORKSHIP3]: '车间3',
  [TabType.WORKSHIP4]: '车间4',
};

export const workShopArr = ['车间1', '车间2', '车间3', '车间4'];
