import { Button, EllipsisSpan, Form, Input, Key, PopConfirm, ShowInput, Table } from '@maxtropy/components';
import { FormInstance, FormListFieldData, Space } from 'antd';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import CabintModal from './CabintModal';
import { cloneDeep, isNil } from 'lodash-es';
import { ShareDataContext } from '../../..';
import DeviceModal from './DeviceModal';

export interface IEachWorkshop {
  index: number;
}

const EachWorkshop: FC<IEachWorkshop> = props => {
  const { index } = props;
  const [cabintVisible, setCabintVisible] = useState<boolean>(false); // 机台弹窗
  const [deviceVisible, setDeviceVisible] = useState<boolean>(false); // 设备弹窗
  const [cabintRecord, setCabintRecord] = useState<any>(); // 保存每一行
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]); // 选择的设备

  const eachMachineIndex = useRef<number>(0);

  const { form, isEdit } = useContext(ShareDataContext);

  const addCabinet = (index: number) => {
    console.log(index);
    setCabintVisible(true);
  };

  console.log('index', index);
  console.log(111, form?.getFieldsValue());
  // form?.setFieldValue(['workShopList', Number(index), 'machineList'], [{ machineName: '111' }]);

  const columns = [
    {
      title: '机台',
      dataIndex: 'machineName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      fixed: 'right' as const,
      render: (record: any) => {
        console.log('record', record);
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setCabintVisible(true);
                setCabintRecord(record);
              }}
            >
              编辑机台
            </Button>
            <Button
              type="link"
              onClick={() => {
                setDeviceVisible(true);
                setCabintRecord(record);
              }}
            >
              编辑设备
            </Button>
            <PopConfirm
              okText="继续"
              title={'确定删除'}
              onConfirm={() => {
                const list = form?.getFieldValue(['workShopList', index, 'machineList']) ?? [];
                const originalList = cloneDeep(list);
                const removeIndex = originalList.find((i: any) => i.machineNumber === record.machineNumber);
                form?.setFieldValue(['workShopList', index, 'machineList'], originalList.splice(removeIndex, 1));
              }}
            >
              <Button type="link">删除</Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  // 机台重置
  const onCabintReset = () => {
    setCabintVisible(false);
    setCabintRecord(undefined);
  };

  // 设备重置
  const onDeviceReset = () => {
    setDeviceVisible(false);
    setCabintRecord(undefined);
  };

  // 选择的设备给到最外层form
  useEffect(() => {
    if (!cabintRecord) return;
    const list = form?.getFieldValue(['workShopList', index, 'machineList']) ?? [];
    const originalList = cloneDeep(list);
    form?.setFieldValue(
      ['workShopList', index, 'machineList'],
      originalList.splice(cabintRecord.machineNumber, 1, {
        ...cabintRecord,
        deviceIds: selectedRowKeys,
      })
    );
  }, [selectedRowKeys, cabintRecord]);

  console.log('最后结果', form?.getFieldValue('workShopList'));

  return (
    <>
      <Form.Item
        label={`车间名称`}
        name={[index, 'workShopName']}
        rules={[
          { required: true, message: '车间名称' },
          { min: 1, max: 20 },
        ]}
      >
        <Input style={{ width: '20%' }} placeholder="请输入" />
      </Form.Item>
      <div style={{ width: 90, marginBottom: 8 }}>
        <Button type="primary" onClick={() => addCabinet(index)}>
          新增机台
        </Button>
      </div>
      <Form.Item name={[index, 'machineList']} style={{ marginBottom: 0, width: '50%' }} valuePropName="dataSource">
        <Table rowKey={() => Math.random()} columns={columns} pagination={false} />
      </Form.Item>
      {/* 机台弹窗（新建+编辑） */}
      <CabintModal
        cabintVisible={cabintVisible}
        cabintRecord={cabintRecord}
        onCancel={() => {
          onCabintReset();
        }}
        onOk={val => {
          console.log('val', val);
          // 编辑
          if (!isNil(cabintRecord)) {
            // setDataSource(dataSource.splice(cabintIndex, 1, val));
            const arr = form?.getFieldValue(['workShopList', index, 'machineList']) ?? [];
            const list = cloneDeep(arr);
            console.log('cabintRecord', cabintRecord);
            console.log('editList', list.splice(cabintRecord.machineNumber, 1, val));
            form?.setFieldValue(
              ['workShopList', index, 'machineList'],
              list.splice(cabintRecord?.machineNumber, 1, val)
            );
          } else {
            // 新增
            // const list = cloneDeep(dataSource);
            // list.push(val);
            // setDataSource(list);
            const arr = form?.getFieldValue(['workShopList', index, 'machineList']) ?? [];
            const list = cloneDeep(arr);
            list.push({ ...val, machineNumber: eachMachineIndex.current++ });
            console.log('list', list);
            form?.setFieldValue(['workShopList', index, 'machineList'], list);
          }
          onCabintReset();
        }}
      />
      {/* 设备弹窗（编辑） */}
      <DeviceModal
        deviceVisible={deviceVisible}
        cabintRecord={cabintRecord}
        selectedRowKeys={selectedRowKeys}
        // setSelectedRowKeys={setSelectedRowKeys}
        onCancel={() => {
          onDeviceReset();
        }}
        onOk={val => {
          console.log('val', val);
          setSelectedRowKeys(val);
          setDeviceVisible(false);
        }}
      />
    </>
  );
};

export default EachWorkshop;
