import {
  addBtnConfig,
  addOrEditBsaBtnConfig,
  AreaType,
  BsaBtnConfigReq,
  BsaDataConfigRes,
  BtnConfigActionType,
  deleteBtnConfig,
  getBsaDataConfig,
  getPortalBtnList,
  MajorShowBtnConfigListRes,
  StationType,
  StationTypeFormtter,
  updatePortalBtn,
  UpdatePortalBtnReq,
} from '@/api/energyAssetsPortalConfig';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Table,
  Form,
  InputNumber,
  Modal,
  PopConfirm,
  Radio,
  Button,
  EllipsisSpan,
  useUpdate,
} from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import React, { Key, useEffect, useRef, useState } from 'react';
import EditBtnModalForm, { IFieldForm } from '../EditBtnModalForm';

const columns = [
  {
    title: '序号',
    dataIndex: 'sortNum',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '按钮名称',
    dataIndex: 'buttonName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'URL',
    dataIndex: 'url',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => {
      return <EllipsisSpan value={v} />;
    },
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
];

const BsaShowBtnConfig = () => {
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [topDataLoading, setTopDataLoading] = useState(false);

  const [dataSource, setDataSource] = useState<MajorShowBtnConfigListRes[]>();

  const [updateState, updateFn] = useUpdate();
  const [updateTopState, updateTopFn] = useUpdate();

  const [row, setRow] = useState<MajorShowBtnConfigListRes>();
  const [addBtnTypeModal, setAddBtnTypeModal] = useState<BtnConfigActionType>();

  const [bsaDataConfig, setBsaDataConfig] = useState<BsaDataConfigRes>();
  const cFormRef = useRef<IFieldForm>();

  useEffect(() => {
    setLoading(true);
    getPortalBtnList({ type: AreaType.bsaStation }).then(res => {
      setDataSource(
        res.map((i, index) => {
          return {
            ...i,
            sortNum: index + 1,
          };
        })
      );
      setLoading(false);
    });
  }, [updateState]);

  useEffect(() => {
    setTopDataLoading(true);
    getBsaDataConfig().then(res => {
      setBsaDataConfig(res);
      form.setFieldsValue({
        type: res.type,
        num: res.num,
        installedCapacity: res.installedCapacity,
        bsaCellNum: res.bsaCellNum,
        ratedPower: res.ratedPower,
      });
      setTopDataLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTopState]);

  // 保存上层表单
  const onFinish = (val: BsaBtnConfigReq) => {
    addOrEditBsaBtnConfig(val).then(_ => {
      updateTopFn();
      setIsEdit(false);
    });
  };

  // 重置
  const init = () => {
    setRow(undefined);
    setAddBtnTypeModal(undefined);
    cFormRef.current?.reset();
  };

  const handleEditBtn = (v: UpdatePortalBtnReq | undefined) => {
    if (v) {
      if (v.id) {
        updatePortalBtn(v).then(_ => {
          updateFn();
          init();
        });
      } else {
        addBtnConfig(v).then(_ => {
          updateFn();
          init();
        });
      }
    } else {
      init();
    }
  };

  const removeMajorArea = (id: Key) => {
    deleteBtnConfig(id).then(_ => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 183,
      render: (_: undefined, record: MajorShowBtnConfigListRes) => (
        <>
          <Space size={16}>
            <PopConfirm
              title={'确定删除？'}
              onConfirm={() => removeMajorArea(record.id)}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link" style={{ padding: 0 }}>
                删除
              </Button>
            </PopConfirm>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                setRow(record);
                setAddBtnTypeModal(BtnConfigActionType.ADDBSA);
              }}
            >
              编辑
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const addBsaBtn = () => {
    if (dataSource && dataSource?.length >= 4) {
      Modal.error({ title: <span style={{ color: '#fff' }}>最多新建4个按钮！</span> });
    } else {
      setRow(undefined);
      setAddBtnTypeModal(BtnConfigActionType.ADDBSA);
    }
  };

  const onCancel = () => {
    setIsEdit(false);
    form.setFieldsValue({
      type: bsaDataConfig?.type,
      num: bsaDataConfig?.num,
      installedCapacity: bsaDataConfig?.installedCapacity,
      bsaCellNum: bsaDataConfig?.bsaCellNum,
      ratedPower: bsaDataConfig?.ratedPower,
    });
  };

  return (
    <div>
      {isEdit ? (
        <Space size={8}>
          <Button
            key="saveBsa"
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            保存
          </Button>
          <Button
            key="cancelBsa"
            onClick={() => {
              onCancel();
            }}
          >
            取消
          </Button>
        </Space>
      ) : (
        <Button
          key="editBsa"
          type="primary"
          onClick={() => {
            setIsEdit(true);
          }}
        >
          编辑
        </Button>
      )}
      <Spin spinning={topDataLoading}>
        <Form
          style={{ marginTop: 12 }}
          form={form}
          labelAlign="left"
          onFinish={(val: BsaBtnConfigReq) => onFinish(val)}
        >
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item name="type" label="站点统计数据来源" rules={[{ required: true, message: '请选择' }]}>
                <Radio.Group style={{ width: '100%' }} disabled={!isEdit}>
                  <Radio value={StationType.PlatformArea}>{StationTypeFormtter[StationType.PlatformArea]}</Radio>
                  <Radio value={StationType.ConfigArea}>{StationTypeFormtter[StationType.ConfigArea]}</Radio>
                  <Radio value={StationType.Disable}>{StationTypeFormtter[StationType.Disable]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item noStyle dependencies={['type']}>
                {({ getFieldValue }) =>
                  getFieldValue('type') === StationType.ConfigArea && (
                    <>
                      <Form.Item name="num" label="储能站数量" rules={[{ required: true, message: '请输入' }]}>
                        <InputNumber
                          disabled={!isEdit}
                          style={{ width: '80%' }}
                          addonAfter="个"
                          min={0}
                          max={99999999}
                          placeholder="请输入"
                        />
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item noStyle dependencies={['type']}>
                {({ getFieldValue }) =>
                  getFieldValue('type') === StationType.ConfigArea && (
                    <>
                      <Form.Item
                        name="installedCapacity"
                        label="总装机容量"
                        rules={[{ required: true, message: '请输入' }]}
                      >
                        <InputNumber
                          disabled={!isEdit}
                          style={{ width: '80%' }}
                          addonAfter="MWh"
                          min={0}
                          max={99999999}
                          placeholder="请输入"
                        />
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item noStyle dependencies={['type']}>
                {({ getFieldValue }) =>
                  getFieldValue('type') === StationType.ConfigArea && (
                    <>
                      <Form.Item name="ratedPower" label="总额定功率" rules={[{ required: true, message: '请输入' }]}>
                        <InputNumber
                          disabled={!isEdit}
                          style={{ width: '80%' }}
                          addonAfter="MW"
                          min={0}
                          max={99999999}
                          placeholder="请输入"
                        />
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item noStyle dependencies={['type']}>
                {({ getFieldValue }) =>
                  getFieldValue('type') === StationType.ConfigArea && (
                    <>
                      <Form.Item name="bsaCellNum" label="储能柜数量" rules={[{ required: true, message: '请输入' }]}>
                        <InputNumber
                          disabled={!isEdit}
                          style={{ width: '80%' }}
                          addonAfter="个"
                          min={0}
                          max={99999999}
                          placeholder="请输入"
                        />
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <div>
        <div
          style={{
            marginBottom: 10,
          }}
        >
          <Button type="primary" onClick={() => addBsaBtn()}>
            新建
          </Button>
          <span style={{ marginLeft: 12 }}>
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
            <span style={{ marginLeft: 4, color: 'rgba(255,255,255,0.45)' }}>最多新建4个按钮</span>
          </span>
        </div>
        <Table
          scroll={{ y: 450 }}
          rowKey="id"
          loading={loading}
          className="tableScroll"
          columns={[...buildColumns]}
          dataSource={dataSource}
          pagination={false}
        />
        <EditBtnModalForm cref={cFormRef} row={row} addBtnTypeModal={addBtnTypeModal} handleEditBtn={handleEditBtn} />
      </div>
    </div>
  );
};

export default BsaShowBtnConfig;
