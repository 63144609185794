import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  SubContent,
  FormTitle,
  Form,
  Table,
  ShowInput,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { columns } from '../util';
import { getFcsInfo, getFcsType } from '../../../api/fcs';
import { Device } from '../../../api/device';
import { FcsNetworkDetail, fetchFcsNetworkByEdgeGatewayId, Gateway } from '../../../api/gateway';
import styles from '../Create/index.module.scss';
import classNames from 'classnames';
import { isNil } from 'lodash-es';

const routes = [{ name: 'FCS详情' }];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const FcsDetail: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [deviceList, setDeviceList] = useState<Device[]>([]);
  const [currentGateway, setCurrentGateway] = useState<Gateway>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [fcsNetworkInfo, setFcsNetworkInfo] = useState<FcsNetworkDetail>();
  useEffect(() => {
    if (id) {
      getFcsInfo(id).then(res => {
        form.setFieldsValue({
          ...res,
          ou: res.ouVoV2.name,
          gateway: res.edgeGatewayAbstractVo.name,
        });
        setCurrentGateway(res.edgeGatewayAbstractVo);
        setDeviceList(
          res.edgeGatewayAbstractVo.deviceWithEdgeDeviceDtos?.map(item => ({
            ...item.device,
          }))
        );
        fetchFcsNetworkByEdgeGatewayId(String(res.gatewayId)).then(res => {
          if (res) {
            setFcsNetworkInfo(res);
          }
        });
        getFcsType().then(r => {
          if (r.list && r.list.length > 0) {
            form.setFieldValue('type', r.list.find(i => i.type === res.type)?.name);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
      <Form form={form} layout="vertical" {...formLayout}>
        <FormTitle title={routes[0].name} />
        <SubContent>
          <Row>
            <Col span={8}>
              <Form.Item label="FCS编号" name="serialNumber">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="FCS名称" name="name">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="FCS类型" name="type">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  let softwareVersion = getFieldValue('softwareVersion');
                  let softwareName = getFieldValue('softwareName');
                  return (
                    <Form.Item label="FCS软件版本">
                      <ShowInput value={`${softwareVersion ?? ''}${softwareName ? '-' + softwareName : ''}`} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="关联运营单元" name="ou">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="备注" name="remark">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="关联网关"
                name="gateway"
                extra={
                  <div className={styles.colBoxChild}>
                    <div className={styles.rowItemBox}>
                      <div className={classNames(styles.rowItem, styles.rowItemLeft)}>网关元数据采集设备:</div>
                      <div className={classNames(styles.rowItem, styles.rowItemCenter)}>
                        {currentGateway?.deviceName ?? '未配置元数据采集设备'}
                      </div>
                      {!currentGateway?.deviceName && !isNil(currentGateway?.id) ? (
                        <div className={classNames(styles.rowItem, styles.rowItemRight)}>
                          <Link to={`/device/config/gateway/update/${currentGateway?.id}`} target="_blank">
                            点击编辑网关
                          </Link>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={styles.rowItemBox}>
                      <div className={classNames(styles.rowItem)}>所在组网:</div>
                      <div className={classNames(styles.rowItem)}>{fcsNetworkInfo?.netName ?? '--'}</div>
                    </div>
                    <div className={styles.rowItemBox}>
                      <div className={classNames(styles.rowItem)}>ip地址:</div>
                      <div className={classNames(styles.rowItem)}>{fcsNetworkInfo?.gatewayIp ?? '--'}</div>
                    </div>
                  </div>
                }
              >
                <ShowInput />
              </Form.Item>
            </Col>

            {/* <Col span={24}>
              <div style={{ position: 'relative', width: '100%', height: '30px' }}>
                {currentGateway && <GatherDeviceList {...currentGateway} />}
              </div>
            </Col> */}
          </Row>
          <p style={{ paddingTop: 50 }}>网关中绑定的设备</p>
          <Table
            columns={columns}
            dataSource={deviceList}
            sticky
            className="tableScroll"
            scroll={{ y: 500 }}
            pagination={{
              defaultPageSize: 20,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50', '100'],
              showTotal: () => `共${deviceList.length}条`,
            }}
          />
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button style={{ marginTop: 20 }}>
            <Link to={`/device/fcs/list`}>返回</Link>
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default FcsDetail;
