import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { columnsBefore, getColumns } from './columns';
import { ActionType, DataPointType, VirtualPoint } from '../../../../types';
import FormDialog from './FormDialog';
import { formatOnSave, parseOnEcho } from '../../../FormulaInput';
import { useQuery } from '../../../../utils/utils';
import { CrossDataPropertiesResponse, getCrossDataProperties, getDataProperties } from '../../../../api/options';
import { PointContext, PointContextProps } from '../../contextTypes';
import Header from '../Header';
import { Button, FieldTable, MxColumnType, MxColumnsType } from '@maxtropy/components';
import { useParams } from 'react-router-dom';
import { FilterMode } from '@/shared/const';

export type EdgeDeviceTemplatePoint = VirtualPoint & { actionType?: ActionType };

interface VirtualPointProps {
  loading?: boolean;
  editColumns?: MxColumnsType<EdgeDeviceTemplatePoint>;
  onUpdate?: (values: any) => any;
}

const indexColumn: MxColumnType<EdgeDeviceTemplatePoint> = {
  title: '序号',
  width: 70,
  fixed: 'left',
  render: (_, record, index) => index + 1,
};

const Virtual: FC<VirtualPointProps> = props => {
  const { loading, editColumns, onUpdate } = props;

  const { dataSource, row, setRow, info, promptSlot, editable, canAdjustField, isBatch } = useContext(
    PointContext
  ) as PointContextProps<EdgeDeviceTemplatePoint>;

  const _row = useMemo(() => {
    if (row) {
      const { parameters, formula, ...rest } = row;
      return {
        ...rest,
        formula: parseOnEcho(formula),
        ...parameters,
        valueFilterMode: parameters?.valueFilterMode ?? FilterMode.NUMBERREASONABLERANGE,
        valueRange: [parameters?.valueFloor, parameters?.valueCeiling],
        cloudValueRange: [parameters?.cloudValueFloor, parameters?.cloudValueCeiling],
      };
    } else {
      return row;
    }
  }, [row]);

  const usedProperties = useMemo(
    () =>
      dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item.dataPropertyId !== row?.dataPropertyId)
        .map(item => item.dataPropertyId) || [],
    [dataSource, row]
  );

  const usedIdentifier = useMemo(
    () =>
      (dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item.identifier !== row?.identifier)
        .map(item => item.identifier)
        .filter(Boolean) as string[]) || [],
    [dataSource, row]
  );

  const { isLoading: requiredLoading, data: dataProperties = [] } = useQuery(
    useCallback(
      () =>
        info?.deviceTypeId
          ? getDataProperties(info.iotProtocol, info.deviceTypeId, info.physicalModelId)
          : Promise.resolve([]),
      [info?.iotProtocol, info?.deviceTypeId, info?.physicalModelId]
    )
  );

  // 跨设备虚拟点
  const [crossDeviceDatapropertyList, setCrossDeviceDatapropertyList] = useState<CrossDataPropertiesResponse[]>([]);
  const { id } = useParams<{ id: string }>(); // 批量设备时, 这个id是模版id
  useEffect(() => {
    if (!id || isBatch) return;
    getCrossDataProperties({ id }).then(setCrossDeviceDatapropertyList);
  }, [id]);

  const dataPropertiesMap = useMemo(() => {
    /**
     * 1.本设备 key-value 为 datapropertyId -> datapropertyName ,
     * 2.跨设备 key-value 为 datapropertyId_deviceId -> datapropertyName + '@' + deviceName
     * 3.非建模点不存在datapropertyId为null, 跨设备后端端会把标识符字段放在datapropertyName中, 也要做下对应关系如下
     * 4.跨设备非建模点key-value 为 datapropertyName_deviceId -> datapropertyName + '@' + deviceName
     */
    let oldMap = new Map(dataProperties.map(item => [String(item.id), item.name]));
    let newsMap = new Map(
      (crossDeviceDatapropertyList ?? []).map(item => {
        let isLocal = String(item.deviceId) === id;
        let localKey = item.datapropertyId;
        let crossKey = (item.datapropertyId ?? item.datapropertyName) + '_' + item.deviceId;
        return [
          `${isLocal ? localKey : crossKey}`,
          `${isLocal ? item.datapropertyName : item.datapropertyName + '@' + item.deviceName}`,
        ];
      })
    );
    return new Map([...oldMap, ...newsMap]);
  }, [dataProperties, crossDeviceDatapropertyList]);

  const onOk = (values: any) => {
    const { valueRange, formula, cloudValueRange, valueFilterMode, cloudValueFilterCnt, ...rest } = values;
    const _values = {
      ...rest,
      pointType: DataPointType.VIRTUAL_POINT,
      formula: formatOnSave(formula),
      parameters: {
        driveType: info?.driveType,
        pointType: DataPointType.VIRTUAL_POINT,
        valueCeiling: valueRange?.[1],
        valueFloor: valueRange?.[0],
        cloudValueCeiling: cloudValueRange?.[1],
        cloudValueFloor: cloudValueRange?.[0],
        valueFilterMode,
        cloudValueFilterCnt,
      },
    };
    onUpdate?.(_values);
  };

  return (
    <>
      <Header slot={promptSlot}>
        {editable && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setRow?.({ pointType: DataPointType.VIRTUAL_POINT })}
          >
            添加虚拟点
          </Button>
        )}
      </Header>
      <FieldTable
        sticky
        rowKey="id"
        adjustField={canAdjustField}
        adjustWidth={canAdjustField}
        cacheKey="virtualPoint"
        scroll={{ y: 500 }}
        loading={loading || requiredLoading}
        dataSource={dataSource?.filter(item => item.pointType === DataPointType.VIRTUAL_POINT)}
        columns={[
          indexColumn,
          ...columnsBefore,
          ...(canAdjustField ? editColumns || [] : []),
          ...getColumns(dataPropertiesMap),
          ...(!canAdjustField ? editColumns || [] : []),
        ]}
        pagination={false}
      />
      <FormDialog
        onCancel={() => setRow?.(undefined)}
        onOk={onOk}
        usedProperties={usedProperties}
        dataProperties={dataProperties}
        crossDeviceDatapropertyList={crossDeviceDatapropertyList}
        usedIdentifier={usedIdentifier}
        row={_row}
      />
    </>
  );
};

export default Virtual;
