import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import Header from './components/Header';
import { useRequest, useSize } from 'ahooks';
import { cancelFullscreen, goFullscreen, setFitScale } from './utils';
import CenterLeftBox from './components/CenterLeftBox';
import CenterGraph from './components/CenterBottomBox';
import CenterRightBox from './components/CenterRightBox';
import bg from './imgs/bg.png';
import CenterBottomBox from './components/CenterBottomBox';

const ZYFDashboard = () => {
  const resizeRef = useRef<any>();
  const baseDivRef = useRef<HTMLDivElement>();
  const size = useSize(baseDivRef);
  const innerDivRef = useRef<HTMLDivElement>();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false); // 是否全屏

  const handleResize = () => {
    if (size?.width && size?.width > 1920) {
      setFitScale(innerDivRef.current, size?.width, size?.height);
    }
  };

  useEffect(() => {
    if (resizeRef.current) {
      window.removeEventListener('resize', handleResize);
    }
    resizeRef.current = window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [size?.width]);

  const fullBtn = () => {
    if (baseDivRef.current) {
      isFullScreen ? cancelFullscreen() : goFullscreen(baseDivRef.current);
    }
  };

  return (
    <div
      className={styles.outer_wrap}
      id="full_screen_box"
      style={{ overflowY: 'hidden' }}
      ref={ref => {
        if (ref) {
          baseDivRef.current = ref;
        }
      }}
    >
      <div
        className={styles.inner}
        ref={ref => {
          if (ref) {
            innerDivRef.current = ref;
          }
        }}
      >
        {/* 上方header区域 */}
        <div className={styles.inner_top}>
          <Header fullBtn={fullBtn} isFullScreen={isFullScreen} />
        </div>
        {/* 中间content区域 */}
        <div className={styles.inner_center}>
          <div className={styles.center_left_box}>
            {/* 左侧（用能趋势） */}
            <CenterLeftBox />
          </div>
          <div className={styles.center_center_box}>
            {/* 中间下面的计数 */}
            <CenterBottomBox />
          </div>

          <div className={styles.center_left_box}>
            {/* 右侧（绿色工厂） */}
            <CenterRightBox />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZYFDashboard;
