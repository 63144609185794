import {
  Button,
  DatePicker,
  Form,
  FormContent,
  FormTitle,
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
} from '@maxtropy/components';
import { FormInstance, Row, Space, Spin } from 'antd';
import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import MappingRelation from './components/MappingRelation';
import GreenElec from './components/GreenElec';
import AIEnergy from './components/AIEnergy';
import UnitConsumptionSetting from './components/UnitConsumptionSetting';
import dayjs from 'dayjs';
import { apiV2EnergyCarbonConfigConfigUpsertPost } from '@maxtropy/device-customer-apis-v2';

export interface ShareDataProps {
  form?: FormInstance<any>;
  isEdit?: boolean;
}

const routes = [{ name: '配置' }];

export const ShareDataContext = createContext<ShareDataProps>({});

const ZYFDashboardConfig = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setlLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const onFinish = (values: any) => {
    console.log('values', values);
    const params = {
      startMeasurementDate: dayjs(values?.startMeasurementDate).format('YYYY-MM-DD'),
      areaList: [
        {
          areaNumber: 0,
          energyUnitGroupId: values?.areaOne,
          energyUnitIdList: (values?.energyOne ?? []).map((item: any) => item?.value),
          areaName: values?.areaNameOne,
        },
        {
          areaNumber: 1,
          energyUnitGroupId: values?.areaTwo,
          energyUnitIdList: (values?.energyTwo ?? []).map((item: any) => item?.value),
          areaName: values?.areaNameTwo,
        },
        {
          areaNumber: 2,
          energyUnitGroupId: values?.areaThree,
          energyUnitIdList: (values?.energyThree ?? []).map((item: any) => item?.value),
          areaName: values?.areaNameThree,
        },
      ],
      workShopList: (values?.workShopList ?? []).map((item: any, index: number) => ({
        workShopNumber: index,
        workShopName: item?.workShopName,
        machineList: item?.machineList?.map((c: any, i: number) => ({
          machineNumber: i,
          machineName: c?.machineName,
          machineCode: c?.machineCode?.map((o: any) => o.code),
          deviceIds: c?.deviceIds,
          consumptions: [
            {
              consumptionType: 1,
              consumptionValue: c?.electricEnergy,
            },
            {
              consumptionType: 2,
              consumptionValue: c?.gas,
            },
            {
              consumptionType: 3,
              consumptionValue: c?.steam,
            },
          ],
        })),
      })),
      photovoltaicMeterId: values?.photovoltaicMeterId,
      carbonEmissionFactor: values?.carbonEmissionFactor,
      aiEnergyIndex: {
        electricitySafety: values?.electricitySafety,
        efficiency: values?.efficiency,
        voltageQualificationRate: values?.voltageQualificationRate,
        peakValleyRatio: values?.peakValleyRatio,
        loadRate: values?.loadRate,
      },
    };
    console.log('params', params);
    apiV2EnergyCarbonConfigConfigUpsertPost({ ...params }).then(_ => {
      navigate('/factory/zyf/dashboard');
    });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish}>
          <ShareDataContext.Provider value={{ form, isEdit }}>
            <FormTitle
              title="配置"
              extraContent={
                <Space size={8}>
                  {!isEdit ? (
                    <Button type="primary" onClick={() => setIsEdit(true)}>
                      编辑
                    </Button>
                  ) : (
                    <Button htmlType="submit" type="primary">
                      保存
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      navigate('/factory/zyf/dashboard');
                    }}
                  >
                    返回
                  </Button>
                </Space>
              }
            />
            <SubContent title={`开始计量日期`}>
              <Form.Item label="开始计量日期" name="startMeasurementDate">
                <DatePicker allowClear={false} disabled={!isEdit} style={{ width: 320 }} />
              </Form.Item>
            </SubContent>
            <SubContent title={`区域映射关系`}>
              <MappingRelation />
            </SubContent>
            <SubContent title={`单耗设定`}>
              <UnitConsumptionSetting />
            </SubContent>
            <SubContent title={`绿电降碳`}>
              <GreenElec />
            </SubContent>
            <SubContent title={`AI能源指数`}>
              <AIEnergy />
            </SubContent>
          </ShareDataContext.Provider>
        </Form>
      </Spin>
    </Wrapper>
  );
};

export default ZYFDashboardConfig;
