import React, { useEffect, useMemo, useRef } from 'react';
import CardBox from '../../../CardBox';
import ReactEcharts from 'echarts-for-react';
import { getChartOption } from './chart';
import styles from './index.module.scss';

const AIEnergyIndex = () => {
  const chartRef = useRef<ReactEcharts>();

  // 查询图表
  // const chartOption = useMemo(() => getChartOption(chartsData), [chartsData]);
  const chartOption = useMemo(() => getChartOption(), []);

  const handleResize = () => {
    chartRef.current?.getEchartsInstance().resize();
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <CardBox title="AI能源指数" className={styles.cardBoxSty}>
      <ReactEcharts
        style={{ height: '100%' }}
        ref={d => {
          if (d) {
            chartRef.current = d;
          }
        }}
        option={chartOption}
        notMerge
        lazyUpdate={false}
      />
    </CardBox>
  );
};

export default AIEnergyIndex;
