import React, { FC } from 'react';
import styles from './index.module.scss';
import subTitleBg from '../../imgs/subtittlebg.png';
import AIEnergyIndex from './components/AIEnergyIndex';

const CenterLeftBox = () => {
  return (
    <div className={styles.left_wrapper}>
      <div className={styles.top_bg}>
        <span className={styles.top_bg_text}>绿色工厂</span>
      </div>
      <div className={styles.content_top_box}>
        <AIEnergyIndex />
      </div>
      <div className={styles.content_center_box}></div>
      <div className={styles.content_bottom_box}></div>
    </div>
  );
};

export default CenterLeftBox;
