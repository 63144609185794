import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Modal } from '@maxtropy/components';
import { Col, Row } from 'antd';
import { isNil } from 'lodash-es';
import React, { FC, useEffect } from 'react';

export interface ICabintModal {
  cabintVisible: boolean;
  cabintRecord?: any;
  onCancel: () => void;
  onOk: (data: any) => void;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 18, offset: 6 },
  },
};
const CabintModal: FC<ICabintModal> = props => {
  const { cabintVisible, cabintRecord, onCancel, onOk } = props;
  const [form] = Form.useForm();

  // 新增的时候code必须要有一个
  useEffect(() => {
    if (!cabintVisible) return;
    if (isNil(cabintRecord)) {
      form.setFieldValue('machineCode', [{ code: undefined }]);
    } else {
      console.log('cabintRecord', cabintRecord);
      form.setFieldsValue({
        ...cabintRecord,
      });
    }
  }, [cabintRecord, cabintVisible]);

  return (
    <>
      <Modal
        open={cabintVisible}
        contentClassName="modal-form-content"
        title={!isNil(cabintRecord) ? '编辑机台' : '新增机台'}
        onCancel={() => {
          form.resetFields();
          onCancel();
        }}
        onOk={async () => {
          const data = await form.validateFields();
          onOk(data);
          form.resetFields();
        }}
        destroyOnClose
      >
        <Form form={form}>
          <Form.Item
            name="machineName"
            label="机台名称"
            labelCol={{ span: 5 }}
            rules={[{ required: true, message: '请输入机台名称' }]}
          >
            <Input placeholder="请输入" allowClear />
          </Form.Item>
          <Form.List name="machineCode">
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <Row gutter={16} key={field.key}>
                    <Col span={20}>
                      <Form.Item
                        {...(field.name === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                        style={{ marginLeft: field.name !== 0 ? 10 : undefined }}
                        label={field.name === 0 ? '编码' : ''}
                        name={[field.name, 'code']}
                        rules={[{ required: true, message: '请输入编码' }]}
                      >
                        <Input placeholder="请输入" />
                      </Form.Item>
                    </Col>
                    {fields.length - 1 === field.name && (
                      <Col span={2}>
                        <Form.Item noStyle>
                          <PlusCircleOutlined
                            style={{ color: '#4A90E2', cursor: 'pointer', paddingTop: 8 }}
                            onClick={() => add()}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {fields.length > 1 && (
                      <Col span={2}>
                        <Form.Item noStyle>
                          <MinusCircleOutlined
                            style={{ color: '#4A90E2', cursor: 'pointer', paddingTop: 8 }}
                            onClick={() => remove(field.name)}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item label="目标单耗值" labelCol={{ span: 5 }}>
            <Form.Item
              name="electricEnergy"
              label="电能"
              labelCol={{ span: 5 }}
              rules={[{ required: true, message: '请输入电能' }]}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" addonAfter="kWh/t" />
            </Form.Item>
            <Form.Item
              name="gas"
              label="燃气"
              labelCol={{ span: 5 }}
              rules={[{ required: true, message: '请输入燃气' }]}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" addonAfter="m³/t" />
            </Form.Item>
            <Form.Item
              name="steam"
              label="蒸汽"
              labelCol={{ span: 5 }}
              rules={[{ required: true, message: '请输入蒸汽' }]}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" addonAfter="t/t" />
            </Form.Item>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CabintModal;
