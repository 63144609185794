export const getChartOption = (data?: any) => {
  return {
    color: ['#67F9D8', '#FFE434', '#56A3F1', '#FF917C'],
    grid: {
      left: 10,
      right: 10,
      top: 60,
      bottom: 35,
    },
    legend: {
      show: false,
    },
    radar: [
      {
        indicator: [
          { text: '用电安全' },
          { text: '电压合格率' },
          { text: '负载率' },
          { text: '峰谷比' },
          { text: '效能' },
        ],
        center: ['50%', '50%'],
        radius: 120,
        startAngle: 90,
        splitNumber: 4,
        shape: 'circle',
        axisName: {
          formatter: '{value}',
          color: '#428BD4',
        },
        splitArea: {
          areaStyle: {
            color: ['#77EADF', '#26C3BE', '#64AFE9', '#428BD4'],
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowBlur: 10,
          },
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(211, 253, 250, 0.8)',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(211, 253, 250, 0.8)',
          },
        },
      },
    ],
    series: [
      {
        type: 'radar',
        emphasis: {
          lineStyle: {
            width: 4,
          },
        },
        data: [
          {
            value: [100, 8, 0.4, -80, 2000],
            name: 'Data A',
          },
          {
            value: [60, 5, 0.3, -100, 1500],
            name: 'Data B',
            areaStyle: {
              color: 'rgba(255, 228, 52, 0.6)',
            },
          },
        ],
      },
    ],
  };
};
