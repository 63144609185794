import React, { FC, Key, useContext, useEffect, useMemo, useState } from 'react';
import { Space, Spin } from 'antd';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import FooterTip from '../FooterTip';
import ResizeObserver from 'rc-resize-observer';
import dayjs, { Dayjs } from 'dayjs';
import { BannerResponse, SignType } from '@/api/energyUnitDashboard';
import { EnergyUnitContext } from '..';
import { isNil } from 'lodash-es';
import { Button, DatePicker, Empty, Modal, Radio, Tabs } from '@maxtropy/components';
import { EnergyMediumSceneIds, EnergyMediumSceneIdsColorDisplay } from '@/api/energyMedium';
import { PhysicalQuantity, PhysicalQuantityUnit } from '@/api/energyConsumption';
import {
  V2EnergyUnitBoardDetailListPostResponse,
  apiV2EnergyUnitBoardDetailBannerPost,
  apiV2EnergyUnitBoardDetailEnergyMediumInEnergyUnitPost,
  apiV2EnergyUnitBoardDetailListPost,
} from '@maxtropy/device-customer-apis-v2';
import { useSearchParams } from 'react-router-dom';
import { EnergyUnitItem } from '@/api/energyUnitDashboard';
import DeviceDetailModal from '../DeviceDetailModal';

const { RangePicker } = DatePicker;
export type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface BannerProps extends BannerResponse {
  sign: SignType;
}

interface TabItemProps {
  label: string;
  key: string;
  sceneId: Key;
}

const formatDateString = 'YYYY-MM-DD';
const formatTimeString = 'YYYY-MM-DD HH:mm:ss';

export enum DatePickerType {
  MINUTE = 0,
  DAY,
  MONTH,
}

const DateFormatString = {
  [DatePickerType.MINUTE]: 'YYYY-MM-DD HH:mm',
  [DatePickerType.DAY]: 'YYYY-MM-DD',
  [DatePickerType.MONTH]: 'YYYY-MM',
};

const BarDateFormatString = {
  [DatePickerType.MINUTE]: 'MM-DD HH:mm',
  [DatePickerType.DAY]: 'YYYY-MM-DD',
  [DatePickerType.MONTH]: 'YYYY-MM',
};

const datePickerTypeDisplay = {
  [DatePickerType.MINUTE]: '15分钟',
  [DatePickerType.DAY]: '按日',
  [DatePickerType.MONTH]: '按月',
};

interface UnitDetailProps {
  unitPath?: string;
  times?: RangeValue;
  defaultData: EnergyUnitItem[];
}
export type BarResponseList = Required<Exclude<V2EnergyUnitBoardDetailListPostResponse['list'], undefined>[number]>;
export interface BarResponse {
  avg: number;
  list: BarResponseList[];
}
const UnitDetail: FC<UnitDetailProps> = ({ unitPath, defaultData }) => {
  const [urlSearchParams] = useSearchParams();
  const { unitId, groupId, time, setTime } = useContext(EnergyUnitContext);
  const [tabItems, setTabItems] = useState<TabItemProps[]>([]);
  const [activeKey, setActiveKey] = useState<string>();
  const [dateType, setDateType] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [chartWidth, setChartWidth] = useState<number>();
  const [dates, setDates] = useState<RangeValue | []>();
  const [value, setValue] = useState<RangeValue>([dayjs().subtract(30, 'day'), dayjs()]);
  const [chartData, setChartData] = useState<BarResponse>();
  const [valUnit, setValUnit] = useState<string>('kWh');

  const [bannerValue, setBannerValue] = useState<BannerProps>();
  const url_startDate = urlSearchParams.get('startDate');
  const url_endDate = urlSearchParams.get('endDate');
  // const url_DateType = urlSearchParams.get('dateType');
  const [initFlag, setInitFlag] = useState<boolean>(false);
  const [openDeviceModal, setOpenDeviceModal] = useState<boolean>(false);

  function findEnergyUnitIdById(id: number, data: EnergyUnitItem[]): number | null {
    for (let unit of data) {
      if (unit.energyUnitId === id) {
        return unit.energyUnitId;
      }
      if (unit.children && unit.children.length > 0) {
        const foundId: number | null = findEnergyUnitIdById(id, unit.children);
        if (foundId) {
          return foundId;
        }
      }
    }
    return null;
  }
  // 获取能源介质
  useEffect(() => {
    if (isNil(unitId)) return;
    if (isNil(findEnergyUnitIdById(unitId, defaultData))) {
      return;
    }
    apiV2EnergyUnitBoardDetailEnergyMediumInEnergyUnitPost({ energyUnitId: String(unitId) })
      .onError(error => {
        setTabItems([]);
        setActiveKey(undefined);
        const { errorMessage } = error?.cause;
        Modal.error({
          title: errorMessage,
        });
        throw error;
      })
      .then(res => {
        const items = (res.list ?? []).map(item => ({
          label: item.energyName,
          key: String(item.id),
          sceneId: item.sceneId,
        }));
        setTabItems(items as TabItemProps[]);
        const defaultKey = isNil(items?.[0]?.key) ? undefined : String(items?.[0]?.key);
        setActiveKey(defaultKey);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId, defaultData]);

  useEffect(() => {
    if (url_startDate && url_endDate && !initFlag) {
      setValue([dayjs(url_startDate), dayjs(url_endDate)]);
      // if (url_DateType) {
      //   setDateType(0);
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setInitFlag(true);
      return;
    }
    // 同步时间，总览页到详情时间同步
    if (!isNil(time)) {
      const bannerStartTime =
        DatePickerType.MONTH === dateType
          ? time[0]?.startOf('month').startOf('day').format(formatTimeString)
          : time[0]?.startOf('day').format(formatTimeString);
      const bannerEndTime =
        DatePickerType.MONTH === dateType
          ? dayjs().isSame(time[1], 'month')
            ? time[1]?.format(formatTimeString)
            : time[1]?.endOf('month').format(formatTimeString)
          : dayjs().isSame(time[1], 'day')
          ? time[1]?.format(formatTimeString)
          : time[1]?.endOf('day').format(formatTimeString);
      switch (dateType) {
        case DatePickerType.MINUTE:
          setValue([dayjs(bannerStartTime), dayjs(bannerEndTime)]);
          // setTime?.([dayjs().startOf('day'), dayjs()]);
          break;
        // case DatePickerType.DAY:
        //   setValue([dayjs().subtract(30, 'day'), dayjs()]);
        case DatePickerType.DAY:
          setValue([dayjs(bannerStartTime), dayjs(bannerEndTime)]);
          // setTime?.([dayjs().startOf('day'), dayjs()]);
          break;
        case DatePickerType.MONTH:
          setValue([dayjs().startOf('month').subtract(11, 'month'), dayjs()]);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateType]);

  // 切换日期
  useEffect(() => {
    if (isNil(activeKey) || isNil(unitId)) return;
    if (!value || !value[0] || !value[1]) return;

    const commonParams = {
      energyMediumId: activeKey,
      graininess: String(dateType),
    };

    // 获取环比数据
    const bannerStartTime =
      DatePickerType.MONTH === dateType
        ? value[0].startOf('month').startOf('day').format(formatTimeString)
        : value[0]?.startOf('day').format(formatTimeString);
    const bannerEndTime =
      DatePickerType.MONTH === dateType
        ? dayjs().isSame(value[1], 'month')
          ? value[1].format(formatTimeString)
          : value[1].endOf('month').format(formatTimeString)
        : dayjs().isSame(value[1], 'day')
        ? value[1].format(formatTimeString)
        : value[1].endOf('day').format(formatTimeString);

    apiV2EnergyUnitBoardDetailBannerPost({
      ...commonParams,
      startTime: bannerStartTime,
      endTime: bannerEndTime,
      energyUnitGroupId: String(groupId),
      energyUnitId: String(unitId),
    }).then(res => {
      const formatDateString = DateFormatString[dateType as DatePickerType];
      setBannerValue({
        ...res,
        minConsumeDate: res?.minConsumeDate ? dayjs(res?.minConsumeDate, 'x').format(formatDateString) : '',
        maxConsumeDate: res?.maxConsumeDate ? dayjs(res?.maxConsumeDate, 'x').format(formatDateString) : '',
        sign: !res?.cycleRatio ? SignType.NONE : res?.cycleRatio > 0 ? SignType.PLUS : SignType.MINUS,
      } as BannerProps);
      setValUnit('kWh');
    });

    setLoading(true);
    const barStartTime =
      DatePickerType.MONTH === dateType
        ? value[0].startOf('month').format(formatDateString)
        : value[0]?.format(formatDateString);
    const barEndTime =
      DatePickerType.MONTH === dateType
        ? value[1].endOf('month').format(formatDateString)
        : value[1]?.format(formatDateString);
    // 获取柱状图数据
    apiV2EnergyUnitBoardDetailListPost({
      ...commonParams,
      startDate: barStartTime,
      endDate: barEndTime,
      energyUnitId: String(unitId),
    })
      .then(res => {
        setChartData(res as unknown as BarResponse);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, activeKey, unitId]);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    let tooLate = null;
    let tooEarly = null;
    const afterToday = current && current > dayjs().endOf('day');
    if (DatePickerType.MONTH === dateType) {
      tooLate = dates[0] && current.diff(dates[0], 'months') >= 23;
      tooEarly = dates[1] && dates[1].diff(current, 'months') >= 23;
    } else {
      const limitDays = dateType === DatePickerType.MINUTE ? 7 : 31;
      tooLate = dates[0] && current.diff(dates[0], 'days') >= limitDays;
      tooEarly = dates[1] && dates[1].diff(current, 'days') >= limitDays;
    }
    return !!tooEarly || !!tooLate || !!afterToday;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([]);
    }
  };

  const getChartOption = () => {
    let sceneId = tabItems.find(item => item.key === activeKey)?.sceneId;
    const mediumName = tabItems.find(item => item.key === activeKey)?.label;
    const mediumSceneId = sceneId ? sceneId : 'elec';
    const barColor = EnergyMediumSceneIdsColorDisplay[mediumSceneId as EnergyMediumSceneIds];

    return {
      grid: {
        left: 60,
        right: 60,
        top: 50,
        bottom: 60,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: 2,
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 12,
        },
        formatter: function (params: any) {
          const { seriesName, value } = params[0];
          const formatDateString = BarDateFormatString[dateType as DatePickerType];
          const date = dayjs(value[0], 'x').format(formatDateString);
          const styleStr =
            'display:flex;width:200px;justify-content:space-between;font-size: 12px;font-weight: 500;color: rgba(255,255,255,0.65);line-height:17px;margin-top:8px;';
          let str =
            `<span style='font-size: 12px;color: rgba(255,255,255,0.65);line-height: 17px;'>${date}</span>` +
            '<br>' +
            `<div style='${styleStr}'><div>${seriesName}</div><div>${value[1] ?? '--'}${valUnit}</div></div>` +
            `<div style='${styleStr}'><div>均值</div><div>${chartData?.avg ?? '--'}${valUnit}</div></div>`;
          return str;
        },
      },
      xAxis: {
        type: 'time',
        minInterval:
          dateType === DatePickerType.MONTH
            ? 60 * 1000 * 60 * 24 * 30
            : dateType === DatePickerType.DAY
            ? 60 * 1000 * 60 * 24
            : dateType === DatePickerType.MINUTE
            ? 60 * 1000
            : undefined,
        axisLabel: {
          formatter: function (e: number) {
            const formatDateString = BarDateFormatString[dateType as DatePickerType];
            return dayjs(e, 'x').format(formatDateString);
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        name: valUnit,
        nameTextStyle: {
          fontSize: 14,
          align: 'right',
        },
        nameGap: 30,
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.3)',
          },
        },
      },
      legend: {
        show: false,
      },
      series: {
        name: mediumName,
        type: 'bar',
        barWidth: dateType === DatePickerType.MINUTE ? 2 : 20,
        color: barColor,
        emphasis: {
          focus: 'series',
        },
        data: chartData?.list?.map(i => [i.time, i.value]),
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          data: [
            {
              label: {
                formatter: `均值: {c}${valUnit}`,
                position: 'insideEndTop',
                color: '#59D744',
                fontSize: 14,
                fontWeight: 600,
              },
              yAxis: chartData?.avg ?? 0,
              lineStyle: {
                type: 'dashed',
                color: '#59D744',
              },
            },
          ],
        },
      },
      dataZoom: [
        {
          type: 'slider',
          bottom: 5,
          height: 20,
          backgroundColor: 'rgba(226,227,229,0.5)',
          fillerColor: 'rgba(142,177,224,0.3)',
          textStyle: {
            fontSize: 10,
          },
        },
        { type: 'inside' },
      ],
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _option = useMemo(() => getChartOption(), [chartData, valUnit]);

  return (
    <div className={styles.unitDetail}>
      {tabItems.length > 0 ? (
        <>
          <div className={styles.tabWrapper}>
            <Tabs
              selectedLinePlacement="top"
              onChange={key => {
                setActiveKey(key);
              }}
              activeKey={activeKey}
              items={tabItems}
              bordered
              tabBarGutter={40}
            ></Tabs>
          </div>
          <div className={styles.panel}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <div className={styles.title}>
                <span>当前选择</span>
                <span>{unitPath}</span>
              </div>
              <Space size={16}>
                <Radio.Group onChange={e => setDateType(e.target.value)} value={dateType} buttonStyle="solid">
                  {Object.entries(datePickerTypeDisplay).map(([k, v]) => (
                    <Radio.Button key={k} value={+k}>
                      {v}
                    </Radio.Button>
                  ))}
                </Radio.Group>
                <RangePicker
                  allowClear={false}
                  picker={dateType === DatePickerType.MONTH ? 'month' : 'date'}
                  value={value}
                  disabledDate={disabledDate}
                  onCalendarChange={val => {
                    setDates([val[0], null]);
                    setTime?.(val);
                  }}
                  onOpenChange={onOpenChange}
                  onChange={val => setValue(val)}
                />
                <Button type="link" onClick={() => setOpenDeviceModal(true)}>
                  设备原始数据
                </Button>
              </Space>
              <div className={styles.cardWrapper}>
                <div className={styles.left}>
                  <div className={styles.unit}>总量（{valUnit ?? '--'}）</div>
                  <div className={styles.specific}>
                    <div className={styles.num}>{bannerValue?.total ?? '--'}</div>
                    <div className={styles.val}>
                      <span>环比</span>
                      <span className={styles[bannerValue?.sign!]}>
                        {bannerValue?.sign === SignType.PLUS ? '+' : ''}
                        {bannerValue?.cycleRatio ?? '--'}%
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.right}>
                  <div className={styles.peakVal}>
                    <div className={styles.valLine}>
                      <div>最大用量（{valUnit ?? '--'}）</div>
                      <div></div>
                      <div>{bannerValue?.maxConsume ?? '--'}</div>
                      <div>{bannerValue?.maxConsumeDate ?? '--'}</div>
                    </div>
                    <div className={styles.valLine}>
                      <div>最小用量（{valUnit ?? '--'}）</div>
                      <div></div>
                      <div>{bannerValue?.minConsume ?? '--'}</div>
                      <div>{bannerValue?.minConsumeDate ?? '--'}</div>
                    </div>
                  </div>
                  <div className={styles.menuVal}>
                    <div>用量均值（{valUnit ?? '--'}）</div>
                    <div>{bannerValue?.avgConsume ?? '--'}</div>
                  </div>
                </div>
              </div>
              <ResizeObserver onResize={({ width }) => setChartWidth(width)}>
                <Spin spinning={loading}>
                  {chartData?.list && chartData?.list?.length > 0 ? (
                    <ReactEcharts
                      option={_option}
                      notMerge
                      lazyUpdate={false}
                      style={{ height: 500, width: chartWidth, marginBottom: 30 }} // 样式的设置width与height
                    />
                  ) : (
                    <Empty style={{ height: 500, paddingTop: 160, marginBottom: 30 }} />
                  )}
                </Spin>
              </ResizeObserver>
              <FooterTip dates={DatePickerType.MONTH === dateType ? null : value} />
            </Space>
          </div>
          {openDeviceModal && (
            <DeviceDetailModal setOpen={setOpenDeviceModal} energyUnitGroupId={unitId} value={value} />
          )}
        </>
      ) : (
        <Empty style={{ paddingTop: '10%' }} />
      )}
    </div>
  );
};

export default UnitDetail;
