import { getDeviceList } from '@/api/device';
import { useQuery } from '@/utils/utils';
import { Form, Input, InputNumber, Select } from '@maxtropy/components';
import React, { useCallback, useMemo } from 'react';

const GreenElec = () => {
  const { data } = useQuery(
    useCallback(
      () =>
        getDeviceList({
          typeId: [5002, 8888, 5022],
          page: 1,
          size: 500,
        }).then(res => {
          return res.list ?? [];
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
  );

  const typeOptions = useMemo(() => {
    if (!data) return [];
    return (data ?? []).map(item => ({
      label: item.name,
      value: item.id,
    }));
  }, [data]);

  return (
    <>
      <Form.Item
        label="光伏并网点电表"
        name="photovoltaicMeterId"
        rules={[{ required: true, message: '请选择光伏并网点电表' }]}
      >
        <Select
          style={{ width: '40%' }}
          showSearch
          placeholder="请选择光伏并网点电表"
          optionFilterProp="label"
          options={typeOptions}
        />
      </Form.Item>

      <Form.Item
        label="碳排放因子"
        name="carbonEmissionFactor"
        rules={[{ required: true, message: '请输入碳排放因子' }]}
      >
        <InputNumber style={{ width: '40%' }} placeholder="请输入" addonAfter="kgCO2e/kWh" />
      </Form.Item>
    </>
  );
};

export default GreenElec;
